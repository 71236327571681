import styled from "styled-components";

export const AnswerContentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isLast"].includes(prop),
})`
  &:hover {
    .answer-operate {
      display: block;
    }
  }
  .answer-operate {
    display: ${(prop) => (prop.isLast ? "block" : "none")};
    margin-left: 39px;
  }
  .image-box {
    width: 340px;
    border-radius: 12px;
    overflow: hidden;
  }
`;
