import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { timeSort } from "@/utils";
import { getHistoryChat, getUserInfo } from "@/Api";

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: {
    historyChat: {},
    userInfo: {},
    isClickSidebar: false,
    isClickNewchat: false,
  },
  reducers: {
    setHistoryChat: (state, action) => {
      state.historyChat = action.payload;
    },
    setIsClickSidebar: (state, action) => {
      state.isClickSidebar = action.payload;
    },
    setIsClickNewchat: (state, action) => {
      state.isClickNewchat = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(historyChatList.fulfilled, (state, action) => {
      const historyData = timeSort(action.payload?.history || []);
      state.historyChat = {
        favorites: action.payload?.favorites || [],
        ...historyData,
      };
    });
    builder.addCase(getUserInfoData.fulfilled, (state, action) => {
      state.userInfo = action.payload || {};
    });
  },
});
const historyChatList = createAsyncThunk("chat/history", async () => {
  return await getHistoryChat();
});
const getUserInfoData = createAsyncThunk("user/info", async () => {
  return await getUserInfo();
});
export const {
  setHistoryChat,
  setIsClickSidebar,
  setIsClickNewchat,
  setCurrentChatId,
} = sidebarSlice.actions;

export const historyChatStatus = (state) => state.sidebar.historyChat;
export const userInfoStatus = (state) => state.sidebar.userInfo;
export const clickSidebar = (state) => state.sidebar.isClickSidebar;
export const clickNewchat = (state) => state.sidebar.isClickNewchat;

export { historyChatList, getUserInfoData };

export default sidebarSlice.reducer;
