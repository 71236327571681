import React, { memo } from "react";

import classNames from "classnames";
import { ChatPaginationWrapper } from "./style";
import IconSvg from "../SvgIcon";

export default memo((props) => {
  const { className, len = 2, currentPage = 1, changeHandle } = props;
  const pageChange = (value) => {
    changeHandle(value);
  };
  return (
    <ChatPaginationWrapper className={className}>
      <IconSvg
        name="ArrowLeft"
        className={classNames("f-s-16 cursor-pointer", {
          disabled: currentPage <= 1,
        })}
        disabled={currentPage <= 1}
        onClick={() => pageChange(currentPage - 1)}
      />
      <span>
        {currentPage}/{len}
      </span>
      <IconSvg
        name="ArrowRight"
        className={classNames("f-s-16 cursor-pointer", {
          disabled: currentPage >= len,
        })}
        disabled={currentPage >= len}
        onClick={() => pageChange(currentPage + 1)}
      />
    </ChatPaginationWrapper>
  );
});
