import React, { memo } from "react";

import classNames from "classnames";
import { ControlWrapper } from "./style";
import IconSvg from "@/components/SvgIcon";

export default memo((props) => {
  const { icon, className, clickHandle = () => ({}) } = props;
  return (
    <ControlWrapper
      className={classNames(className, "cursor-pointer")}
      onClick={(e) => clickHandle()}
    >
      <IconSvg name={icon} className="icon-style" />
    </ControlWrapper>
  );
});
