export const colorVariable = {
  bobblue1: "#2e4ef5",
  bobblue2: "#306ff5",
  primary: "#2603f4",
  primaryblue30: "#b7cbfb",
  primaryblue20: "#d6e2fd",
  primaryblue15: "#e8eefd",
  primaryblue5: "#f6f8fe",
  neutral10: "#f9fbfc",
  neutral20: "#f1f3f9",
  neutral30: "#e8edf0",
  neutral35: "#e4e8ed",
  neutral40: "#d0d9de",
  neutral50: "#adb2b8",
  neutral70: "#686f78",
  neutral80: "#323d57",
  neutral90: "#040405",
  assistSafe10: "#d5f2dd",
  assistSafe50: "#2bbf57",
  textQuaternary5: "#e7ebee",
  dark5: "#21252b",
  othersWhite: "#fff",
  othersBlack: "#000",
  othersPop: "#353b44",
  othersMask: "rgba(4, 4, 5, 0.3)",
  assistDanger50: "#f40303",
};
export const styleVariable = {
  headerHeight: "56px",
  contentPadding: "24px",
  sidebarWidth: "250px",
  contextWidth: "740px",
};
