import React, { memo } from "react";

import IconSvg from "@/components/SvgIcon";
import classNames from "classnames";
import { ButtonWrapper } from "./style";

export default memo((props) => {
  const {
    className,
    children,
    icon,
    type = "default",
    block = false,
    padding,
    onClick,
    borderRadius,
    disabled,
    size = "large",
  } = props;

  const handleClick = (e) => {
    onClick?.(e);
  };
  return (
    <ButtonWrapper
      onClick={handleClick}
      className={classNames(
        "chat-button",
        className,
        `chat-btn-${type}`,
        `chat-btn-${size}`,
        {
          "chat-btn-block": block,
          "is-disabled": disabled,
        }
      )}
      padding={padding}
      borderRadius={borderRadius}
    >
      {icon && <IconSvg name={icon} />}
      <span>{children}</span>
    </ButtonWrapper>
  );
});
