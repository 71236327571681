//导入东西顺序 第三方 =》工具类、网络请求 =》 组件
import React, { memo } from "react";
import { Provider } from "react-redux";

import store from "@/store";
import { ConfigProvider } from "antd";
import Layout from "./layout";

export default memo(function App() {
  return (
    <Provider store={store}>
      <ConfigProvider theme={{ token: { colorPrimary: "#2e4ef5" } }}>
        <Layout />
      </ConfigProvider>
    </Provider>
  );
});
