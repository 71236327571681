import styled from "styled-components";

import { colorVariable } from "@/common/globalStyle";

const { othersWhite, bobblue2 } = colorVariable;

export const MenuWrapper = styled.div`
  .menuItemStyle {
    display: flex;
    align-items: center;
    height: 32px;
    line-height: 32px;
    padding: 0 12px;
    border-radius: 8px;
    color: ${othersWhite};
    font-size: 12px;
    &:hover {
      background-color: ${bobblue2};
    }
  }
`;
