import React, { memo, useState } from "react";
import { Tooltip } from "antd";
import classNames from "classnames";
import { ChatAnswerOperateWrapper } from "./style";

import IconSvg from "../SvgIcon";
import ChatOnLike from "../ChatOnLike";
import ChatUnLike from "../ChatUnLike";

export default memo((props) => {
  const {
    className,
    item,
    clipboardHandle,
    likeHandle,
    unLikeHandle,
    regenerateHandle,
    isLast,
    likeAnimation,
    unlikeAnimation,
  } = props;
  const [islike, setIsLike] = useState(item.like);
  const [isUnlike, setIsUnLike] = useState(item.dislike);

  const likeClick = (value) => {
    setIsLike(value);
    likeHandle(value);
    setIsUnLike(false);
  };
  const unLikeClick = (value) => {
    setIsUnLike(value);
    setIsLike(false);
    unLikeHandle(value);
  };
  const regenerateClick = () => {
    regenerateHandle();
  };
  return (
    <ChatAnswerOperateWrapper className={className}>
      <Tooltip title="复制">
        <div
          className="operate-icon cursor-pointer"
          onClick={() => {
            clipboardHandle();
          }}
        >
          <IconSvg name="copy" className="f-s-16" />
        </div>
      </Tooltip>
      <Tooltip title="喜欢">
        <div
          className={classNames("operate-icon cursor-pointer")}
          onClick={() => likeClick(islike ? false : true)}
        >
          <ChatOnLike
            animation={likeAnimation}
            className={classNames({ "display-none": !islike })}
          />
          <IconSvg
            name="like"
            className={classNames({ "display-none": islike }, "f-s-16")}
          />
        </div>
      </Tooltip>
      <Tooltip title="不喜欢">
        <div
          className={classNames("operate-icon cursor-pointer")}
          onClick={() => unLikeClick(isUnlike ? false : true)}
        >
          <ChatUnLike
            animation={unlikeAnimation}
            className={classNames({ "display-none": !isUnlike })}
          />
          <IconSvg
            name="dislike"
            className={classNames({ "display-none": isUnlike }, "f-s-16")}
          />
        </div>
      </Tooltip>

      {isLast && (
        <Tooltip title="重新生成">
          <div
            className="operate-icon cursor-pointer"
            onClick={() => regenerateClick()}
          >
            <IconSvg name="refresh" className="f-s-16" />
          </div>
        </Tooltip>
      )}
    </ChatAnswerOperateWrapper>
  );
});
