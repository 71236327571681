import styled from "styled-components";
import { colorVariable, styleVariable } from "@/common/globalStyle.js";

const { neutral90, neutral40 } = colorVariable;
const { headerHeight } = styleVariable;

export const HeaderWrapper = styled.div`
  position: relative;
  height: ${headerHeight};
  display: flex;
  align-items: center;
  .login-img {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .imgStyle {
    height: 26px;
  }
  .imgAfter {
    width: 1px;
    height: 16px;
    margin: 0 10px 0 5px;
    background-color: ${neutral40};
  }
  .title {
    font-family: PingFangSC;
    font-size: 15px;
    font-weight: 600;
    color: ${neutral90};
  }
`;
