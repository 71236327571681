import React, { memo } from "react";

import { Input } from "antd";
import IconSvg from "@/components/SvgIcon";
import { ConfigProvider } from "antd";
import "./index.less";
import { debounce } from "@/utils";

const config = {
  token: {
    colorText: "#64748b",
  },
};
export default memo(({ submit }) => {
  const changeHandle = (value) => {
    let fn = debounce(submit(value), 300);
    fn();
  };
  return (
    <ConfigProvider theme={config}>
      <Input
        placeholder="搜索历史记录"
        variant="borderless"
        size="large"
        className="input-search-style"
        onChange={(e) => changeHandle(e.target.value)}
        prefix={<IconSvg name="search" />}
      />
    </ConfigProvider>
  );
});
