import styled from "styled-components";
import { colorVariable } from "@/common/globalStyle";

const { neutral30 } = colorVariable;

export const ControlWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: ${neutral30};
  border-radius: 8px;
  .icon-style {
    font-size: 16px;
  }
`;
