import React, { memo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChatMenu from "../ChatMenu";
import { HeaderWrapper } from "./style";
import ChatUser from "../ChatUser";
import logo from "@/assets/img/logo.png";
import { getUserInfoData, userInfoStatus } from "@/services/sidebar";

export default memo(() => {
  const dispatch = useDispatch();
  const userInfo = useSelector(userInfoStatus);
  useEffect(() => {
    dispatch(getUserInfoData());
  }, []);

  return (
    <HeaderWrapper>
      <div
        className="login-img"
        onClick={(e) => {
          window.open(process.env.REACT_APP_POTAL_PATH);
        }}
      >
        <img className="imgStyle" src={logo} alt="" />
      </div>
      <div className="imgAfter"></div>
      <div className="title">大模型体验中心</div>
      <ChatMenu />
      <ChatUser value={userInfo} />
    </HeaderWrapper>
  );
});
