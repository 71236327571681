import React, { memo, useState } from "react";
import { MenuWrapper } from "./style";
import classNames from "classnames";

const menuList = [
  {
    label: "体验中心",
    url: process.env.REACT_APP_EBCHAT_PATH,
  },
  {
    label: "文档",
    url: process.env.REACT_APP_DOCS_PATH,
  },
  {
    label: "控制台",
    url: process.env.REACT_APP_EBTECH_PATH,
  },
];
export default memo(() => {
  // const [currentMenu, setCurrentMenu] = useState("体验中心");

  return (
    <MenuWrapper>
      {menuList.map((item) => {
        return (
          <div
            key={item.label}
            onClick={(e) => {
              console.log(item.url);
              window.open(item.url);
            }}
            className={classNames(
              // { isActive: currentMenu === item.label },
              "itemStyle"
            )}
          >
            {item.label}
            {item.label === "消息" && <div className="badge">1</div>}
          </div>
        );
      })}
    </MenuWrapper>
  );
});
