import React, { memo } from "react";
import { CardWrapper } from "./style";

export default memo((props) => {
  const { padding } = props;
  return (
    <CardWrapper padding={padding} className={props.className}>
      {props.children}
    </CardWrapper>
  );
});
