import React, { memo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message, Image } from "antd";
import ChatConversation from "@/components/ChatConversation";
import ChatAnswerOperate from "@/components/ChatAnswerOperate";
import ChatPagination from "@/components/ChatPagination";
import ChatMarkdown from "@/components/ChatMarkdown";
import { useMsgStyle } from "@/utils/msg-style";
import { AnswerContentWrapper } from "./style";

import {
  chatContextDataObject,
  setChatDialogue,
  setChatContext,
  setCurrentContentPendding,
  lastContentId,
  setCurrentContentId,
  setpageChangeChatList,
} from "@/services/chatContext";
import {
  chatContentLike,
  chatContentLikeUndo,
  chatContentDislike,
  chatContentDislikeUndo,
} from "@/Api";
import classNames from "classnames";
export default memo((props) => {
  const { item, regenerate, isLast, className, img, marked } = props;
  const { styles } = useMsgStyle();
  const dispatch = useDispatch();
  const chatContext = useSelector(chatContextDataObject);
  const currentContentId = useSelector(lastContentId);
  const [likeAnimation, setLikeAnimation] = useState(false);
  const [unlikeAnimation, setunlikeAnimation] = useState(false);
  const answerList =
    (chatContext.contents && chatContext.contents[item.parent]?.children) || [];
  const path = process.env.REACT_APP_PBLIC_URL;
  const likeHandle = (status, value) => {
    console.log(value);
    let params = {
      content_id: value.id,
    };
    if (status) {
      setLikeAnimation(true);
      chatContentLike(params).then((res) => {
        console.log(res);

        setTimeout(() => {
          setLikeAnimation(false);
        }, 600);
        if (res && value.dislike) {
          unLikeHandle(false, value);
        }
      });
    } else {
      setLikeAnimation(false);
      chatContentLikeUndo(params).then((res) => {
        console.log(res);
      });
    }
  };
  const unLikeHandle = (status, value) => {
    let params = {
      content_id: value.id,
    };
    if (status) {
      setunlikeAnimation(true);
      chatContentDislike(params).then((res) => {
        console.log(res);
        setTimeout(() => {
          setunlikeAnimation(false);
        }, 600);
        if (res && value.like) {
          likeHandle(false, value);
        }
      });
    } else {
      setunlikeAnimation(false);
      chatContentDislikeUndo(params).then((res) => {
        console.log(res);
      });
    }
  };
  const clipboardHandle = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        console.log("copy success");
        message.open({
          className: styles["my-message-class"],
          type: "success",
          content: "复制成功",
        });
      },
      () => {
        console.log("copy failed");
        message.error({
          className: styles["my-message-class"],
          type: "error",
          content: "复制失败",
        });
      }
    );
  };

  const pageChangeHandle = (value) => {
    const id = answerList[value - 1];
    const chatContextObject = { ...chatContext };
    let resultId = "";
    if (chatContextObject["contents"][id].children.length === 0) {
      resultId = id;
    } else {
      function lastAnswerId(id) {
        const chatContextObject = { ...chatContext };
        const currentId = chatContextObject["contents"][id].children[0];
        if (
          chatContextObject["contents"][currentId] &&
          chatContextObject["contents"][currentId].children.length !== 0
        ) {
          lastAnswerId(
            chatContextObject["contents"][currentId].children[0],
            resultId
          );
        } else {
          resultId = currentId ? currentId : id;
        }
      }
      lastAnswerId(id);
    }
    chatContextObject.current_content_id = resultId;
    dispatch(setCurrentContentId(resultId));
    dispatch(setChatContext({ ...chatContextObject }));
    dispatch(setChatDialogue(resultId));
    dispatch(
      setpageChangeChatList({ id: resultId, chatContex: chatContextObject })
    );
    if (answerList[answerList.length - 1] === resultId) {
      dispatch(setCurrentContentPendding(true));
    } else {
      dispatch(setCurrentContentPendding(false));
    }
  };
  const currentPage = () => {
    if (answerList[answerList.length - 1] === currentContentId) {
      return answerList.length;
    } else {
      if (answerList.indexOf(currentContentId) !== -1) {
        return answerList.indexOf(currentContentId) + 1;
      } else {
        return answerList.indexOf(item.id) + 1;
      }
    }
  };
  const regenerateHandle = (value) => {
    dispatch(setCurrentContentPendding(true));
    regenerate(value);
  };
  return (
    <AnswerContentWrapper isLast={isLast} className={classNames(className)}>
      <ChatConversation
        img={img}
        isError={item.data.code && item.data.code !== 0}
        cursorFlicker={item.pending}
        content={
          (item.data.type > 99 && item.data.type < 200) ||
          item.data.type === "image" ? (
            <div className="image-box">
              <Image
                src={path + "/backend-api/v3/image/" + item.data.parts[0]}
                width={340}
              />
            </div>
          ) : (
            <ChatMarkdown
              marked={marked}
              id={item.id}
              content={item.data.parts}
            />
          )
        }
      />
      <div className={classNames("answer-operate")}>
        <ChatPagination
          len={answerList.length}
          currentPage={currentPage()}
          className={classNames("margin-right", {
            "display-none": !(answerList.length > 1),
          })}
          changeHandle={(value) => {
            pageChangeHandle(value);
          }}
        />

        <ChatAnswerOperate
          item={item}
          isLast={isLast}
          likeAnimation={likeAnimation}
          unlikeAnimation={unlikeAnimation}
          className={classNames("answer-operate-box", {
            "display-none": isLast && item.pending,
          })}
          likeHandle={(value) => likeHandle(value, item)}
          unLikeHandle={(value) => unLikeHandle(value, item)}
          clipboardHandle={() => clipboardHandle(item.data.parts)}
          regenerateHandle={() => {
            regenerateHandle(item);
          }}
        />
      </div>
    </AnswerContentWrapper>
  );
});
