import React, { memo, useState } from "react";

import IconSvg from "@/components/SvgIcon";

import { QuestionEditWrapper } from "./style";

export default memo((props) => {
  const { className } = props;
  const [isEdit, setIsEdit] = useState(false);
  const editHandle = () => {
    setIsEdit(true);
  };
  return (
    <QuestionEditWrapper className={className}>
      {isEdit && (
        <IconSvg
          name="edit"
          className="f-s-16 cursor-pointer"
          onClick={() => editHandle()}
        />
      )}
    </QuestionEditWrapper>
  );
});
