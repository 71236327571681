import React, { memo, useState } from "react";
import { CSSTransition } from "react-transition-group";
import classNames from "classnames";
import ChatButton from "@/components/ChatButton";
import { logout } from "@/Api";
import { UserWrapper, DropdownWrapper, TagWrapper, InfoWrapper } from "./style";
import IconSvg from "@/components/SvgIcon";

export default memo(({ value }) => {
  const [show, setShow] = useState(false);
  const userType = {
    master: "主账号",
    sub: "子账号",
    admin: "管理员",
  };
  const goLogin = () => {
    window.open(process.env.REACT_APP_LOGIN_PATH);
  };
  const goPersonal = () => {
    window.open(process.env.REACT_APP_EBTECH_USER_PATH);
  };
  const goLogout = () => {
    logout({ location: process.env.REACT_APP_EBCHAT_PATH }).then((res) => {
      console.log(process.env.REACT_APP_LOGIN_PATH);
      window.location.href = process.env.REACT_APP_LOGIN_PATH;
    });
  };
  return (
    <>
      {JSON.stringify(value) === "{}" ? (
        <ChatButton type="primary" size="small" onClick={() => goLogin()}>
          登录
        </ChatButton>
      ) : (
        <InfoWrapper
          onMouseEnter={() => {
            setShow(true);
          }}
          onMouseLeave={() => {
            setShow(false);
          }}
        >
          <UserWrapper>
            <div>
              <div className="userName">{value.name}</div>
              <div className="userType">{userType[value.type || "master"]}</div>
            </div>
            <div className="imgbox">
              <img src={value.avatar} alt="" />
            </div>
          </UserWrapper>

          <CSSTransition
            in={show}
            timeout={200}
            classNames="alert"
            unmountOnExit
          >
            <DropdownWrapper>
              <div className="top-box">
                <div className="display-flex align-items">
                  <div className="top-box-user-name omit">{value.name}</div>
                  <TagWrapper
                    className={classNames(
                      "type",
                      (value.type || "master") + "-style"
                    )}
                  >
                    <IconSvg name="confirm" className="m-r-2" />
                    <span>{userType[value.type || "master"]}</span>
                  </TagWrapper>
                </div>
                <div className="top-box-user-id">账号ID：{value.user_id}</div>
              </div>
              <div className="menu">
                <div onClick={() => goPersonal()}>个人中心</div>
                <div onClick={() => goLogout()}>退出登录</div>
              </div>
            </DropdownWrapper>
          </CSSTransition>
        </InfoWrapper>
      )}
    </>
  );
});
