import styled from "styled-components";
import { colorVariable } from "../../common/globalStyle";
const { neutral20 } = colorVariable;
export const InputWrapper = styled.div`
  position: relative;
  padding: 16px 0;
  border-radius: 12px;
  background-color: ${neutral20};
  font-size: 16px;
  .ant-input {
    &::placeholder {
      position: relative;
      font-size: 16px;
    }
  }
  .send-btn {
    position: absolute;
    right: 16px;
    bottom: 16px;
  }
  .arrow-icon {
    font-size: 22px !important;
  }
`;

export const LoadingWrapper = styled.div`
  width: 16px;
  height: 16px;
  .loading,
  .loading > div {
    position: relative;
    box-sizing: border-box;
  }

  .loading {
    width: 27px;
    display: flex;
    font-size: 0;
    left: -5px;
    top: calc((100% - 3px) / 2);
  }

  .loading > div {
    width: 3px;
    height: 3px;
    margin: 0 3px;
    border-radius: 100%;
    background-color: #fff;
    opacity: 0;
    animation: ball-fall 1.3s ease-in-out infinite;
  }

  .loading > div:nth-child(1) {
    animation-delay: -200ms;
  }

  .loading > div:nth-child(2) {
    animation-delay: -100ms;
  }

  .loading > div:nth-child(3) {
    animation-delay: 0ms;
  }

  @keyframes ball-fall {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }

    10% {
      opacity: 0.5;
    }

    20% {
      opacity: 1;
      transform: translateY(0);
    }

    80% {
      opacity: 1;
      transform: translateY(0);
    }

    90% {
      opacity: 0.5;
    }

    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
`;
export const ValueBoxWrapper = styled.div`
  position: absolute;
  left: 0;
  top: -100000000000000000000000000000px;
  font-size: 16px;
  padding: 7px 11px;
  line-height: 26px;
  width: 660px;
  min-height: 40px;
  word-wrap: break-word;
  white-space: pre-line;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
`;
