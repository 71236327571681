import styled from "styled-components";
import { colorVariable, styleVariable } from "@/common/globalStyle";
const { neutral90, neutral70 } = colorVariable;
const { contextWidth } = styleVariable;

export const TipsWrapper = styled.div`
  margin-top: 18vh;
  .title,
  .tips {
    width: ${contextWidth};
    margin: auto;
  }
  .title {
    height: 45px;
    line-height: 45px;
    font-size: 32px;
    font-weight: 600;
    text-align: left;
    color: ${neutral90};
  }
  .tips {
    line-height: 22px;
    font-size: 16px;
    text-align: left;
    color: ${neutral70};
  }
  .m-b-20 {
    margin-bottom: 20px;
  }
`;
