import React, { memo, useState } from "react";

import { CapyCodeWrapper } from "./style";
import IconSvg from "../../../SvgIcon";

export default memo((props) => {
  const { copyHandle } = props;
  const [capyStatus, setCapyStatus] = useState(true);

  const handleClick = (e) => {
    setCapyStatus(false);
    copyHandle();
    setTimeout(() => {
      setCapyStatus(true);
    }, 1000);
  };
  return (
    <CapyCodeWrapper>
      {capyStatus ? (
        <div className="code-capy-box cursor-pointer " onClick={handleClick}>
          <IconSvg name="codeCapy" className="f-s-16 m-r-4" />
          <span>Copy code</span>
        </div>
      ) : (
        <div className="code-capy-box cursor-pointer ">
          <IconSvg name="Frame" className="f-s-16 m-r-4" />
          <span>Copied!</span>
        </div>
      )}
    </CapyCodeWrapper>
  );
});
