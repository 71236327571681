import styled from "styled-components";

import { colorVariable } from "@/common/globalStyle";
const { neutral80 } = colorVariable;

export const ChatAnswerOperateWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  .operate-icon {
    padding: 5px;
    height: 26px;
    svg {
      color: ${neutral80};
    }
  }
  .operate-icon + .operate-icon {
    margin-left: 15px;
  }
`;
