import React, { memo } from "react";
import { TipsWrapper } from "./style";

export default memo(({ description }) => {
  return (
    <TipsWrapper>
      <div className="title m-b-20">🥳 您好！有什么可以帮助您的？</div>
      <div className="tips">
        {description ||
          "作为你的智能伙伴，我既能写文案、想点子，又能陪你聊天、答疑解惑~"}
      </div>
    </TipsWrapper>
  );
});
