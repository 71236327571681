import React, { useEffect, useState } from "react";

import CapyCode from "./components/CapyCode";

// 复制到剪贴板的函数
const copyToClipboard = (context) => {
  if (navigator.clipboard) {
    // 检查clipboard API是否可用
    navigator.clipboard
      .writeText(String(context))
      .then(() => {
        console.log("复制成功！");
      })
      .catch((err) => {
        console.error("复制失败:", err);
      });
  } else {
    console.error("浏览器不支持复制功能");
  }
};

function MarkdownComponent(props) {
  const { content, marked, id } = props;
  const [tokens, setTokens] = useState([]);
  useEffect(() => {
    if (content && content[0]) {
      setTokens(marked.lexer(content[0]));
    }
  }, [content, marked]);
  useEffect(() => {}, []);
  const renderToken = (token, idx) => {
    if (token.type === "code") {
      const { text, raw } = token;
      return (
        <div key={idx} className="markdown-code">
          <CapyCode copyHandle={() => copyToClipboard(text)} />
          <div
            dangerouslySetInnerHTML={{
              __html: marked(raw).replace(/<pre>/g, "<pre id='hljs'>"),
            }}
          ></div>
        </div>
      );
    }
    //  else if (token.type === "paragraph") {
    //   return <p key={idx}>{token.text}</p>;
    // }
    else {
      return (
        <div
          key={idx}
          dangerouslySetInnerHTML={{
            __html: marked(token.raw).replace(/\n(?=\n)/g, "\n<br>"),
          }}
        ></div>
      );
    }
  };
  return (
    <div className="markdown-body" key={id}>
      {tokens.map(renderToken)}
    </div>
  );
}

export default MarkdownComponent;
