import React, { memo } from "react";

import IconSvg from "@/components/SvgIcon";
import { Modal } from "antd";

import ChatButton from "@/components/ChatButton";
import { useStyle } from "@/hooks/modal";

const ChatModal = memo((props) => {
  const { modelType, isModalOpen, closeHandel, confirmHandle } = props;

  const { styles } = useStyle();

  const classNames = {
    body: styles["my-modal-body"],
    mask: styles["my-modal-mask"],
    header: styles["my-modal-header"],
    footer: styles["my-modal-footer"],
    content: styles["my-modal-content"],
  };
  return (
    <>
      <Modal
        title={
          <div className="my-header-style">
            <IconSvg name="warnning" className="warn-icon" />
            <span className="title">警告</span>
            <div className="close-box" onClick={() => closeHandel(false)}>
              <IconSvg name="close" className="close-icon" />
            </div>
          </div>
        }
        closeIcon={false}
        open={isModalOpen}
        width={520}
        footer={
          <>
            <ChatButton
              type="lightPrimary"
              padding="30px"
              onClick={() => closeHandel(false)}
            >
              取消
            </ChatButton>
            <ChatButton
              type="primary"
              padding="30px"
              onClick={() => confirmHandle(false)}
            >
              确定
            </ChatButton>
          </>
        }
        centered
        classNames={classNames}
      >
        <div className="modal-context">
          您目前正在使用
          <span className="model-type">{modelType}</span>
          。请注意，如果您选择更换模型，当前对话将不会继续进行。
        </div>
      </Modal>
    </>
  );
});
export default ChatModal;
