import styled from "styled-components";
import { colorVariable } from "@/common/globalStyle";
const { neutral30, bobblue1 } = colorVariable;

export const EditChatWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
  padding: 0 12px;
  background-color: ${neutral30} !important;
  border: solid 1px ${bobblue1};
  border-radius: 12px;
  .icon-box {
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 4px;
  }
  .duihua-icon {
    font-size: 24px;
  }
`;
