import React, { memo, useRef, useEffect, useState } from "react";

import { Input } from "antd";
import IconSvg from "@/components/SvgIcon";
import { EditChatWrapper } from "./style";

export default memo((props) => {
  const { value, submit } = props;
  const [inputValue, setInputValue] = useState(value);
  const inputRef = useRef(null);
  useEffect(() => {
    inputRef.current.focus();
  }, []);

  const inputChange = (e) => {
    setInputValue(e.target.value);
  };

  const submitHandle = () => {
    let value = inputValue === "" ? "未命名" : inputValue;
    setInputValue(value);
    submit?.(value);
  };
  return (
    <EditChatWrapper>
      <div className="icon-box m-r-10">
        <IconSvg name="duihua" className="duihua-icon"></IconSvg>
      </div>
      <Input
        ref={inputRef}
        variant="borderless"
        value={inputValue}
        onBlur={submitHandle}
        onChange={inputChange}
        onPressEnter={submitHandle}
      />
    </EditChatWrapper>
  );
});
