import styled from "styled-components";
import { styleVariable } from "../../common/globalStyle";
const { contextWidth, headerHeight } = styleVariable;
export const TopBoxWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  .m-l-auto {
    margin-left: auto;
  }
  .m-l-10 {
    margin-left: 10px;
  }
`;
export const CenterBoxWrapper = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 188px - ${headerHeight});
  margin-top: 8px;
  .display-none {
    display: none !important;
  }
`;
export const BottomBoxWrapper = styled.div`
  width: ${contextWidth};
  position: absolute;
  bottom: 24px;
  left: calc((100% - ${contextWidth}) / 2);
  .m-b-40 {
    margin-bottom: 40px;
  }
`;
export const ConversationContentWrapper = styled.div`
  width: 780px;
  padding-top: 32px;
  margin: auto;
  .m-t-2 {
    margin-top: 2px;
  }
  .m-b-20 {
    margin-bottom: 20px;
  }

  .answer-operate {
    position: absolute;
    bottom: 10px;
    left: 0;
  }
  .margin-right {
    margin-right: 15px;
  }
  .content-space {
    position: relative;
    padding-bottom: 40px;
  }
  .my-message-class {
    margin-top: 10vh;
  }
`;
