import React, { memo } from "react";
import { StopGenerateWrapper } from "./style";
import IconSvg from "@/components/SvgIcon";

export default memo((props) => {
  const { onClick } = props;
  const handleClick = (e) => {
    onClick?.(e);
  };
  return (
    <StopGenerateWrapper className="cursor-pointer" onClick={handleClick}>
      <div className="stop-box">
        <IconSvg className="f-s-16 m-r-4" name="stop" />
        <span>停止生成</span>
      </div>
    </StopGenerateWrapper>
  );
});
