import React from "react";
import ReactDOM from "react-dom/client";

import "@/assets/css/index.css";

// svg图标
import "./icons/index";
//markdown 高亮
import "highlight.js/styles/monokai-sublime.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
