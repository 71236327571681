import React, { memo, useState } from "react";

import { Popover } from "antd";
import IconSvg from "@/components/SvgIcon";
import { MenuWrapper } from "./style";
import { colorVariable } from "@/common/globalStyle";

const { othersPop } = colorVariable;

const menuList = [
  {
    label: "编辑",
    icon: "edit",
  },
  // {
  //   label: "导出",
  //   icon: "export",
  // },
  {
    label: "删除",
    icon: "delete",
  },
];

export default memo((props) => {
  const { onClick } = props;
  const [open, setOpen] = useState(false);
  const content = (
    <MenuWrapper>
      {menuList.map((item) => {
        return (
          <div
            className="menuItemStyle cursor-pointer"
            key={item.icon}
            onClick={(e) => menuClick(e, item.icon)}
          >
            <IconSvg name={item.icon} className="icon-style m-r-4" />
            {item.label}
          </div>
        );
      })}
    </MenuWrapper>
  );
  const menuClick = (e, type) => {
    e.stopPropagation();
    setOpen(false);
    onClick?.(type);
  };
  const getPopupContainer = (triggerNode) => {
    // 返回你希望作为弹出层容器的 DOM 节点
    return triggerNode.parentNode;
  };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <Popover
      placement="bottomLeft"
      overlayClassName="my-dark-popover-style"
      content={content}
      arrow={false}
      color={othersPop}
      open={open}
      onOpenChange={handleOpenChange}
      getPopupContainer={getPopupContainer}
    >
      <div className="inline-block m-r-4" onClick={(e) => e.stopPropagation()}>
        <IconSvg name="MenuDots" className="icon-style"></IconSvg>
      </div>
    </Popover>
  );
});
