import styled from "styled-components";

import { colorVariable } from "../../common/globalStyle";

const { othersWhite } = colorVariable;

export const CardWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["padding"].includes(prop),
})`
  position: relative;
  padding: ${(props) => (props.padding ? props.padding : "20px")};
  border-radius: 12px;
  background-color: ${othersWhite};
`;
