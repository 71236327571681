import styled from "styled-components";
import { styleVariable } from "@/common/globalStyle.js";

const { contentPadding, headerHeight } = styleVariable;

export const LayoutWrapper = styled.div`
  height: 100vh;
  padding: 0 ${contentPadding};
  background: url(${require("@/assets/img/bg.png")}) no-repeat;
  background-size: 100% 100%;
  .container-style {
    display: flex;
    height: calc(100vh - ${headerHeight});
    padding-bottom: 16px;
  }
  .content-style {
    flex: 1;
    min-height: 0;
  }
  .m-l-12 {
    margin-left: 12px;
  }
`;
