import styled from "styled-components";

export const ChatOnLikeWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  .union-icon-absolute {
    position: absolute;
    left: calc(50% - 10px);
    top: calc(50% - 8px);
    opacity: 0;
  }
  .onLike-icon-absolute {
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
  }
  .union-animation {
    animation: unionAimation 600ms linear 1;
  }
  @keyframes unionAimation {
    0% {
      transform: scale(0) rotate(0);
    }
    60% {
      opacity: 1;
      transform: scale(2.75) rotate(90deg);
    }
    100% {
      opacity: 0;
      transform: scale(2.75) rotate(150deg);
    }
  }

  .onLike-animation {
    animation: onLikeAimation 600ms ease 1 normal both;
  }
  @keyframes onLikeAimation {
    0% {
      transform: scale(0.625) rotate(-30deg);
    }
    60% {
      transform: scale(1) rotate(10deg);
    }
    100% {
      transform: scale(1) rotate(0);
    }
  }
`;
