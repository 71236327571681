import { message } from "antd";
import { chatFavorite, chatFavoriteUndo } from "@/Api";
import { useMsgStyle } from "../utils/msg-style";

export const useCollect = () => {
  const { styles } = useMsgStyle();
  const collectHandle = (params, isCollect, callBack) => {
    let currentApi = null;
    if (isCollect) {
      currentApi = chatFavoriteUndo;
    } else {
      currentApi = chatFavorite;
    }
    currentApi(params).then((res) => {
      console.log(res);
      if (res) {
        message.open({
          className: styles["my-message-class"],
          type: "success",
          content: isCollect ? "取消收藏成功" : "收藏成功",
        });
        callBack();
      }
    });
  };
  return {
    collectHandle,
  };
};
