import { createStyles } from "antd-style";
import { colorVariable } from "@/common/globalStyle";

const { neutral90, neutral70, neutral80, neutral30, othersMask, bobblue1 } =
  colorVariable;
export const useStyle = createStyles(({ token, css }) => ({
  "my-modal-body": css`
    padding: 32px 0 !important;

    .modal-context,
    .model-type {
      font-size: 14px;
    }
    .modal-context {
      color: ${neutral80};
    }
    .model-type {
      color: ${bobblue1};
    }
  `,

  "my-modal-mask": css`
    background-color: ${othersMask} !important;
  `,
  "my-modal-header": css`
    margin-bottom: 0 !important;
    .my-header-style {
      display: flex;
      align-items: center;
    }
    .warn-icon {
      font-size: 24px;
      margin-right: 8px;
    }
    .title {
      color: ${neutral90};
      font-size: 16px;
    }
    .close-box {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 25px;
      width: 25px;
      margin-left: auto;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: ${neutral30};
      }
    }
    .close-icon {
      font-size: 13px;
      color: ${neutral70};
    }
  `,
  "my-modal-footer": css`
    margin-top: 0 !important;
  `,
  "my-modal-content": css`
    border: 1px solid #333;
  `,
}));
