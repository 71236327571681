import React, { memo, useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import { message } from "antd";

import ChatButton from "@/components/ChatButton";
import ChatSearch from "./components/ChatSearch";
import IconSvg from "@/components/SvgIcon";
import ChatMenu from "./components/ChatOperateMenu";
import ChatItem from "./components/ChatItem";
import EditChat from "./components/EditChat";
import {
  historyChatList,
  historyChatStatus,
  setHistoryChat,
  setIsClickSidebar,
  setIsClickNewchat,
} from "@/services/sidebar";
import {
  getChatContent,
  addChatDialogue,
  setChatContext,
  setChatId,
  setModelId,
  currentChatId,
} from "@/services/chatContext";
import { SidebarWrapper, ChatHistoryWrapper } from "./style";
import { useCollect } from "@/hooks/collect";
import { chatDelete, chatUpdate } from "@/Api";
import { useMsgStyle } from "@/utils/msg-style";

export default memo(() => {
  const dispatch = useDispatch();
  const { styles } = useMsgStyle();
  const current_chat_id = useSelector(currentChatId);
  const [currentChat, setCurrentChat] = useState(current_chat_id);
  const [inputValue, setInputValue] = useState("");
  const { collectHandle } = useCollect();

  const chatList = useSelector(historyChatStatus);
  const titleText = {
    today: "Today",
    yesterday: "Yesterday",
    week: "Previous 7 Days",
    months: "Previous 30 Days",
  };

  useEffect(() => {
    dispatch(historyChatList());
  }, []);
  useEffect(() => {
    if (current_chat_id) {
      setCurrentChat(current_chat_id);
    }
  }, [current_chat_id]);

  const filterChatList = useMemo(() => {
    const obj = {};
    for (let key in chatList) {
      obj[key] = chatList[key].filter((item) => {
        if (inputValue !== "") {
          return item.title.includes(inputValue);
        } else {
          return item.title;
        }
      });
    }
    return obj;
  }, [chatList, inputValue]);

  const chatClick = (value, type) => {
    if (type === "model") {
      setCurrentChat(value);
      dispatch(addChatDialogue([]));
      dispatch(setChatContext({}));
      dispatch(setModelId(value));
    } else {
      dispatch(setChatId(value));
      setCurrentChat(value);
      let params = {
        session_id: value,
      };
      dispatch(setIsClickSidebar(true));
      dispatch(getChatContent(params));
    }
  };
  const creatChat = () => {
    dispatch(addChatDialogue([]));
    dispatch(setChatContext({}));
    dispatch(setChatId(""));
    setCurrentChat("");
    dispatch(setIsClickNewchat(true));
  };
  const collectClick = (e, id) => {
    e.stopPropagation();
    const params = {
      session_id: id,
    };
    collectHandle(params, false, () => {
      dispatch(historyChatList());
    });
  };
  const collectCancelHandle = (e, id, type) => {
    e.stopPropagation();
    const params = {
      [type === "model" ? "model_id" : "session_id"]: id,
    };
    collectHandle(params, true, () => {
      dispatch(historyChatList());
    });
  };
  const menuClick = (type, id, index, time) => {
    console.log(type, id, index, time);
    switch (type) {
      case "delete":
        deleteChat(id);
        break;
      case "edit":
        let history = [...chatList[time]];
        history[index] = {
          ...history[index],
          isEdit: true,
        };

        dispatch(setHistoryChat({ ...chatList, [time]: history }));
        break;
      default:
        console.log("---");
    }
  };
  const deleteChat = (id) => {
    chatDelete({ session_id: id }).then((res) => {
      message.open({
        className: styles["my-message-class"],
        type: "success",
        content: "删除成功",
      });
      dispatch(historyChatList());
    });
  };
  const submitHandle = (value, id, index, time) => {
    let params = {
      session_id: id,
      title: value,
    };
    chatUpdate(params).then((res) => {
      let history = [...chatList[time]];
      history[index] = {
        ...history[index],
        title: value,
        isEdit: false,
      };
      dispatch(historyChatList());
    });
  };
  const searchHandle = (value) => {
    setInputValue(value);
    console.log(value);
  };
  return (
    <SidebarWrapper>
      <div className="operate-box">
        <ChatButton
          icon="edit"
          type="primary"
          block
          borderRadius="12px"
          onClick={() => creatChat()}
        >
          创建新对话
        </ChatButton>
        <ChatSearch submit={(value) => searchHandle(value)} />
      </div>
      <div className="chat-box">
        {filterChatList?.favorites?.length !== 0 && (
          <ChatHistoryWrapper>
            <div className="title">Collection</div>
            {filterChatList.favorites?.map((item) => {
              return (
                <ChatItem
                  value={item}
                  key={item.resource_id}
                  alwaysShow={true}
                  className={classNames({
                    isActive: currentChat === item.resource_id,
                  })}
                  clickHandle={(e) => chatClick(item.resource_id, item.type)}
                >
                  <IconSvg
                    name="Stars2"
                    className="icon-style"
                    onClick={(e) =>
                      collectCancelHandle(e, item.resource_id, item.type)
                    }
                  ></IconSvg>
                </ChatItem>
              );
            })}
          </ChatHistoryWrapper>
        )}
        {Object.keys(titleText).map((itemX) => {
          return (
            filterChatList[itemX]?.length !== 0 && (
              <ChatHistoryWrapper key={itemX}>
                <div className="title">{titleText[itemX]}</div>
                {filterChatList[itemX]?.map((item, index) => {
                  const isCollect = () => {
                    const index = chatList["favorites"].findIndex(
                      (favorites) => {
                        return favorites.resource_id === item.id;
                      }
                    );
                    return index !== -1;
                  };
                  return !item.isEdit ? (
                    <ChatItem
                      value={item}
                      key={item.id}
                      className={classNames({
                        isActive: currentChat === item.id,
                      })}
                      clickHandle={(e) => chatClick(item.id, item.type)}
                    >
                      <ChatMenu
                        onClick={(type) =>
                          menuClick(type, item.id, index, itemX)
                        }
                      />
                      <IconSvg
                        name={isCollect() ? "Stars2" : "Stars"}
                        className="icon-style"
                        onClick={(e) =>
                          isCollect()
                            ? collectCancelHandle(e, item.id, item.type)
                            : collectClick(e, item.id)
                        }
                      ></IconSvg>
                    </ChatItem>
                  ) : (
                    <EditChat
                      key={item.id}
                      value={item.title}
                      submit={(value) =>
                        submitHandle(value, item.id, index, itemX)
                      }
                    />
                  );
                })}
              </ChatHistoryWrapper>
            )
          );
        })}
      </div>
    </SidebarWrapper>
  );
});
