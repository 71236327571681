import { createStyles } from "antd-style";
const useMsgStyle = createStyles(({ css }) => ({
  "my-message-class": css`
    margin-top: 10vh;
    .ant-message-custom-content {
      display: flex;
      align-items: center;
      line-height: normal;
    }
    .anticon {
      vertical-align: unset !important;
      height: 12px;
    }
  `,
}));

export { useMsgStyle };
