import { instance as request, fetchInstance } from "@/utils/request";

//获取个人信息
export function getUserInfo(data) {
  return request({
    url: `${process.env.REACT_APP_USER_PBLIC_URL}/user/detail`,
    method: "GET",
    params: data,
  });
}
//退出登录
export function logout(data) {
  return request({
    url: `${process.env.REACT_APP_USER_PBLIC_URL}/user/logout`,
    method: "GET",
    params: data,
  });
}
//聊天流式响应
export function chatGenerate(params, signal) {
  return fetchInstance("chat/generate", params, signal);
}
//加载当前聊天上下文
export function chatContent(data) {
  return request({
    url: "chat/context",
    method: "GET",
    params: data,
  });
}

//中止当前聊天的生成
export function getChatStop() {
  return request({
    url: "chat/stop",
    method: "GET",
  });
}
//喜欢当前生成的内容
export function chatContentLike(data) {
  return request({
    url: "chat/content/like",
    method: "GET",
    params: data,
  });
}
//取消喜欢某条内容
export function chatContentLikeUndo(data) {
  return request({
    url: "chat/content/like/undo",
    method: "GET",
    params: data,
  });
}

//不喜欢当前生成的内容
export function chatContentDislike(data) {
  return request({
    url: "chat/content/dislike",
    method: "GET",
    params: data,
  });
}
//取消不喜欢某条内容
export function chatContentDislikeUndo(data) {
  return request({
    url: "chat/content/dislike/undo",
    method: "GET",
    params: data,
  });
}
//重新生成当前内容
export function chatContentRegenerate(params, signal) {
  return fetchInstance("chat/regenerate", params, signal);
}
//收藏：可以收藏 模型、app、session
export function chatFavorite(data) {
  return request({
    url: "chat/favorite",
    method: "GET",
    params: data,
  });
}
//取消收藏：可以收藏 模型、app、session
export function chatFavoriteUndo(data) {
  return request({
    url: "chat/favorite/undo",
    method: "GET",
    params: data,
  });
}
//分享当前聊天
export function getChatShare() {
  return request({
    url: "chat/share",
    method: "GET",
  });
}
//更新当前聊天元信息
export function chatUpdate(data) {
  return request({
    url: "chat/update",
    method: "GET",
    params: data,
  });
}

//删除当前聊天记录
export function chatDelete(data) {
  return request({
    url: "chat/delete",
    method: "GET",
    params: data,
  });
}
//当前可用模型
export function modelList() {
  return request({
    url: "model/list",
    method: "GET",
  });
}
//推荐应用
export function getRecommendApps() {
  return request({
    url: "recommend/apps",
    method: "GET",
  });
}
//推荐问题
export function recommendQuestions(data) {
  return request({
    url: "recommend/questions",
    method: "GET",
    params: data,
  });
}
//历史记录
export function getHistoryChat() {
  return request({
    url: "chat/history",
    method: "GET",
  });
}
