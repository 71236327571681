import React, { memo, useState, useRef } from "react";

import { Input } from "antd";
import IconSvg from "../SvgIcon";
import ChatButton from "@/components/ChatButton";
import { InputWrapper, LoadingWrapper } from "./style";
import classNames from "classnames";

const { TextArea } = Input;

const Loading = memo(({ className }) => {
  return (
    <LoadingWrapper className={className}>
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </LoadingWrapper>
  );
});

export default memo((props) => {
  const { sendHandle, loading = false } = props;
  const [inputValue, setInputValue] = useState("");
  const ref = useRef(null);
  const changeHandle = (e) => {
    setInputValue(e.target.value);
  };
  const onPressEnter = (e) => {
    if (!e.shiftKey && e.keyCode === 13) {
      submit();
      e.preventDefault();
    }
  };
  const submit = () => {
    console.log(inputValue, "inputValue");
    if (inputValue === "") {
      return;
    }
    if (!loading) {
      sendHandle(inputValue);
    }

    setInputValue("");
  };
  return (
    <InputWrapper>
      <TextArea
        ref={ref}
        value={inputValue}
        onChange={changeHandle}
        onPressEnter={(e) => onPressEnter(e)}
        placeholder="请输入......"
        variant="borderless"
        size="large"
        autoSize
        style={{
          lineHeight: "20px",
          resize: "none",
          padding: "10px 80px 10px 16px",
        }}
      />

      <ChatButton
        className="send-btn"
        type="primary"
        disabled={inputValue === ""}
        onClick={(e) => submit()}
      >
        <IconSvg
          name="arrow3"
          className={classNames("arrow-icon", {
            "display-none": loading,
          })}
        />

        <Loading
          className={classNames({
            "display-none": !loading,
          })}
        />
      </ChatButton>
    </InputWrapper>
  );
});
