//导入东西顺序 第三方 =》工具类、网络请求 =》 组件
import React, { memo } from "react";
import { BrowserRouter, useRoutes } from "react-router-dom";
import routers from "@/router";

import ChatHeader from "./components/ChatHeader";
import Sidebar from "./components/Sidebar";
import ChatCard from "../components/ChatCard";
import { LayoutWrapper } from "./style";

// 渲染路由
function RouteElement() {
  const element = useRoutes(routers || []);
  return element;
}
export default memo(() => {
  return (
    <BrowserRouter>
      <LayoutWrapper>
        <ChatHeader />
        <div className="container-style">
          <Sidebar />
          <ChatCard className="content-style m-l-12" padding="20px 0">
            <RouteElement />
          </ChatCard>
        </div>
      </LayoutWrapper>
    </BrowserRouter>
  );
});
