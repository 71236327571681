import styled from "styled-components";
import { colorVariable, styleVariable } from "@/common/globalStyle";

const { othersWhite, neutral50, primaryblue20 } = colorVariable;
const { sidebarWidth } = styleVariable;
export const SidebarWrapper = styled.div`
  width: ${sidebarWidth};
  min-width: ${sidebarWidth};
  border-radius: 12px;
  background-color: ${othersWhite};
  padding-bottom: 20px;
  .operate-box {
    padding: 20px 20px 12px 20px;
  }
  .chat-box {
    padding: 0 8px;
    height: calc(100% - 134px);
    overflow-y: auto;
  }
`;

export const ChatHistoryWrapper = styled.div`
  .title {
    display: flex;
    align-items: center;
    height: 36px;
    font-size: 13px;
    color: ${neutral50};
    padding: 0 12px;
  }
  .icon-style {
    font-size: 16px;
  }
  .isActive {
    background-color: ${primaryblue20};
  }
`;
