import axios from "axios";
import { debounce } from "./index";
import { useLoginModel } from "@/hooks/loginTips";

const BASE_URL = `${process.env.REACT_APP_PBLIC_URL}/backend-api/v3/`;

const instance = axios.create({
  baseURL: BASE_URL,
});
const getToken = () => {
  const cookieMap = new Map();
  const cookie = document.cookie;
  cookie.split("; ").forEach((cookie) => {
    const [key, value] = cookie.split("=");
    cookieMap.set(key, value);
  });
  const fixedToken =
    "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxMDAwMDcwLCJhdWQiOiIxMDAwMDcwIiwiZXhwIjoxNzE3NzMxMjA5LCJqdGkiOiIxMDAwMDcwIiwiaWF0IjoxNzE3NzI3NjA5LCJpc3MiOiJib2ItY2xvdWQiLCJuYmYiOjE3MTc3Mjc2MDksInN1YiI6ImxvZ2luXzEwMDAwNzAifQ.m-kPW7zswRHKJOLsQu05srKJoNZImy_o9MYSIKjAQQABD9cYwLRyJCFwS-rp62Ch9AadiARF70M9EWHpy7BP3NxJLY5AIErK4OzpLLlmBoH7rDbv3-N8ZDb0wHDqm5aVfNjzJkADLeGT6Nuz2Rj_EGywbgBNfMULSexhPYXNQCgxcXjrG0rCxDXZu4NDGBwXpOaNr9dSdnQFdmGcBf3euk9hTw5q3Qulx6nVi83GZsFJKoOj5OPwp_6eScIj2KZpff3h2Cout4STf474QP8AIXd7oqY8AiBup7YUjrE8WgUEOlZnHgONk8XMFqcYgJ6l1TF4onQFDT9n5uVUqB_89w";
  // undefined;
  const token =
    process.env.REACT_APP_ENV === "development"
      ? fixedToken
      : cookieMap.get("u");
  return token;
};
//  请求拦截器
instance.interceptors.request.use(
  (config) => {
    // if (config.url.includes("user/detail")) {
    //   config.headers["Token"] = getToken();
    // } else {
    config.headers["Authorization"] = "Bearer " + getToken();
    // }

    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);
const expiredPop = debounce(
  () => {
    const { content } = useLoginModel();
    content();
  },
  300,
  true
);
//请求响应器
instance.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.code !== 0) {
      if (res.code === 2004 || /^5060\d*$/.test(res.code)) {
        expiredPop();
      }
    } else {
      return Promise.resolve(res.data);
    }
  },
  (error) => {
    console.log(error);
  }
);

const fetchInstance = (url, params, signal) => {
  return fetch(
    `${BASE_URL}${url}?${params}`,
    {
      headers: {
        Authorization: "Bearer " + getToken(),
      },
      method: "GET",
    },
    signal
  );
};

export { instance, fetchInstance };
