import React, { memo } from "react";
import classNames from "classnames";
import "./index.css";

const IconSvg = memo(({ className, name, onClick, disabled = false }) => {
  const iconName = `#${name}`;

  const handleClick = (e) => {
    if (disabled) {
      return;
    }
    onClick?.(e);
  };
  return (
    <svg
      className={classNames("icon-svg", className)}
      aria-hidden="true"
      onClick={handleClick}
    >
      <use xlinkHref={iconName} />
    </svg>
  );
});

export default IconSvg;
