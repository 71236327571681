import React, { memo } from "react";
import IconSvg from "../SvgIcon";
import { ChatUnLikeWrapper } from "./style";
import classNames from "classnames";

export default memo((props) => {
  const { onClick, className, animation = true } = props;
  const handleClick = (e) => {
    onClick?.(e);
  };
  return (
    <ChatUnLikeWrapper className={className} onClick={(e) => handleClick(e)}>
      <IconSvg
        name="dislike-fill"
        className={classNames("f-s-16 ", { "unLike-aimation": animation })}
      />
      <div
        className={classNames("unLike-bottom", { "display-none": !animation })}
      >
        <div className="point-box">
          <div className="point-left unLike-point-aimation "></div>
          <div className="point-center unLike-point-aimation "></div>
          <div className="point-right unLike-point-aimation "></div>
        </div>
      </div>
    </ChatUnLikeWrapper>
  );
});
