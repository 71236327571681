import styled from "styled-components";
import { colorVariable } from "@/common/globalStyle";

const { neutral80 } = colorVariable;
export const ChatPaginationWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 26px;
  padding: 4px 0px;
  border-radius: 28px;
  svg,
  span {
    color: ${neutral80};
  }

  span {
    font-size: 14px;
    padding: 0 10px;
  }
`;
