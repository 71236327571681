import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { chatContent, recommendQuestions } from "@/Api";

const getChatContentData = (current_content_id, context) => {
  let chatList = [];
  const getChatData = (id, list, content) => {
    let data = content?.contents[id];
    list.unshift(data);
    if (data.parent) {
      getChatData(data["parent"], list, content);
    }
  };

  getChatData(current_content_id, chatList, context);

  return chatList;
};

const chatContentSlice = createSlice({
  name: "chatContext",
  initialState: {
    chatContextData: {},
    chatDialogue: [],
    pageChangeChatList: [],
    answerData: {},
    chatId: "",
    modelId: "",
    appId: "",
    currentContentId: "",
    currentContentPendding: true,
    isSlideup: false,
  },
  reducers: {
    setChatDialogue: (state, action) => {
      if (JSON.stringify(state.chatContextData) !== "{}") {
        state.chatDialogue = getChatContentData(
          action.payload,
          state.chatContextData
        );
      }
    },
    setpageChangeChatList: (state, action) => {
      console.log(
        action.payload,
        state.chatContextData,
        "setpageChangeChatList"
      );
      state.pageChangeChatList = getChatContentData(
        action.payload.id,
        action.payload.chatContex
      );
    },
    setChatContext: (state, action) => {
      state.chatContextData = action.payload;
    },
    addChatDialogue: (state, action) => {
      state.chatDialogue = action.payload;
    },
    setChatId: (state, action) => {
      state.chatId = action.payload;
    },
    setModelId: (state, action) => {
      state.modelId = action.payload;
    },
    setAppId: (state, action) => {
      state.appId = action.payload;
    },
    setCurrentContentId: (state, action) => {
      state.currentContentId = action.payload;
    },
    setCurrentContentPendding: (state, action) => {
      state.currentContentPendding = action.payload;
    },
    setIsSlideup: (state, action) => {
      state.isSlideup = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChatContent.fulfilled, (state, action) => {
      state.chatContextData = action.payload || {};
      state.modelId = action.payload.model_id;
      if (action.payload) {
        state.chatDialogue = getChatContentData(
          state.chatContextData.current_content_id,
          state.chatContextData
        );
      }
      state.currentContentId = state.chatContextData.current_content_id;
    });
  },
});

const getChatContent = createAsyncThunk("chat/content", async (params) => {
  const response = await chatContent(params);
  return response;
});

//获取state
export const chatDialogueList = (state) => {
  return state.chatContext.chatDialogue;
};

export const chatContextDataObject = (state) =>
  state.chatContext.chatContextData;

export const currentChatId = (state) => state.chatContext.chatId;
export const currentModelId = (state) => state.chatContext.modelId;
export const currentAppId = (state) => state.chatContext.appId;
export const lastContentId = (state) => state.chatContext.currentContentId;
export const currentContentisPend = (state) =>
  state.chatContext.currentContentPendding;
export const pageChangeChat = (state) => state.chatContext.pageChangeChatList;
export const slideup = (state) => state.chatContext.isSlideup;

// 异步actions
export { getChatContent };

//同步actions
export const {
  setChatDialogue,
  addChatDialogue,
  setChatId,
  setModelId,
  setChatContext,
  setCurrentContentId,
  setCurrentContentPendding,
  setpageChangeChatList,
  setIsSlideup,
} = chatContentSlice.actions;

export default chatContentSlice.reducer;
