import moment from "moment";
export const assembleParams = (params) => {
  let paramsArr = [];
  for (let key in params) {
    console.log(key);
    if (params[key] && params[key] !== "") {
      paramsArr.push(`${key}=${params[key]}`);
    }
  }
  const paramsStr = paramsArr.join("&");
  return paramsStr;
};

export const timeSort = (data) => {
  const history = {
    today: [],
    yesterday: [],
    week: [],
    months: [],
  };
  const today = moment();

  const yesterday = moment().subtract(1, "day");
  const week = moment().subtract(1, "week");
  const months = moment().subtract(1, "months");
  for (let item of data) {
    if (moment(moment(item.update_ts, "X")).isSame(today, "day")) {
      history["today"].push(item);
    } else if (moment(moment(item.update_ts, "X")).isSame(yesterday, "day")) {
      history["yesterday"].push(item);
    } else if (moment(moment(item.update_ts, "X")).isSame(week, "week")) {
      history["week"].push(item);
    } else if (moment(moment(item.update_ts, "X")).isSame(months, "months")) {
      history["months"].push(item);
    }
  }
  return history;
};

//防抖
export function debounce(func, wait, immediate) {
  let timeout, args, context, timestamp, result;

  const later = function () {
    // 据上一次触发时间间隔
    const last = +new Date() - timestamp;

    // 上次被包装函数被调用时间间隔 last 小于设定时间间隔 wait
    if (last < wait && last > 0) {
      timeout = setTimeout(later, wait - last);
    } else {
      timeout = null;
      // 如果设定为immediate===true，因为开始边界已经调用过了此处无需调用
      if (!immediate) {
        result = func.apply(context, args);
        if (!timeout) context = args = null;
      }
    }
  };

  return function (...args) {
    context = this;
    timestamp = +new Date();
    const callNow = immediate && !timeout;
    // 如果延时不存在，重新设定延时
    if (!timeout) timeout = setTimeout(later, wait);
    if (callNow) {
      console.log("immediate");
      result = func.apply(context, args);
      context = args = null;
    }
    return result;
  };
}
