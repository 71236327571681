import styled from "styled-components";
import { colorVariable } from "../../common/globalStyle";
const { neutral20, neutral80, othersWhite, bobblue1, primaryblue20 } =
  colorVariable;

export const TabWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  height: 40px;
  padding: 4px;
  border-radius: 12px;
  background-color: ${neutral20};
  .itemStyle {
    display: inline-flex;
    align-items: center;
    padding: 0 10px;
    height: 32px;
    text-align: center;
    border-radius: 12px;
    font-size: 14px;
    cursor: pointer;
  }
  .isActive {
    background: ${othersWhite};
  }
  .arrow-icon {
    font-size: 12px;
    margin-left: 4px;
  }
`;

export const PopoverContentWrapper = styled.div`
  .select-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding: 0 8px;
    border-radius: 8px;
    font-size: 14px;
    color: ${neutral80};
    cursor: pointer;
    span {
      color: inherit;
      font-size: inherit;
    }
    &:hover {
      background: ${primaryblue20};
    }
  }
  .select-item + .select-item {
    margin-top: 4px;
  }
  .isSelect {
    color: ${bobblue1};
  }
  .select-icon-box {
    width: 16px;
    height: 16px;
    margin-left: 40px;
  }
  .select-icon {
    font-size: 16px;
  }
  .no-show {
    display: none;
  }
`;
