import styled from "styled-components";

import { colorVariable } from "../../common/globalStyle";
const {
  bobblue1,
  primaryblue15,
  primaryblue20,
  neutral50,
  neutral60,
  neutral90,
  othersWhite,
} = colorVariable;

export const ButtonWrapper = styled("div").withConfig({
  shouldForwardProp: (prop) => !["padding", "borderRadius"].includes(prop),
})`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 ${(props) => (props.padding ? props.padding : "16px")};
  font-size: 14px;
  font-weight: 500;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "8px"};
  cursor: pointer;

  svg {
    font-size: 16px;
  }
  span {
    font-size: 13px;
  }
  [class*="icon-svg"] + span:not(:empty) {
    margin-left: 6px;
  }
  &.chat-button + &.chat-button {
    margin-left: 12px;
  }
  &.chat-btn-primary {
    background: ${bobblue1};
    color: ${othersWhite};
  }
  &.chat-btn-large {
    height: 40px;
  }
  &.chat-btn-small {
    height: 32px;
  }
  &.chat-btn-block {
    display: flex;
  }
  &.chat-btn-lightPrimary {
    background: ${primaryblue20};
    color: ${neutral90};
  }
  &.is-disabled {
    cursor: not-allowed;
    color: ${neutral50};
    background-color: ${primaryblue15};
    border-color: ${neutral60};
  }
`;
