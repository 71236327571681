import styled from "styled-components";
import { colorVariable } from "@/common/globalStyle";
const { othersBlack, neutral20, neutral35, neutral80, neutral90, neutral70 } =
  colorVariable;
export const QuestionWrapper = styled.div`
  width: 740px;
  margin: auto;
  .title {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    color: ${othersBlack};
    margin-bottom: 17px;
  }
  .context {
    display: flex;
    flex-wrap: wrap;
  }
  .questionItem {
    position: relative;
    /* display: flex;
    align-items: center; */
    /* height: 66px; */
    width: calc((100% - 18px) / 2);
    background-color: ${neutral20};
    padding: 12px 20px;
    margin-bottom: 18px;
    border-radius: 12px;
  }
  .questionItem:hover {
    background-color: ${neutral35};
    .arrow-box {
      display: block;
    }
  }
  .questionItem:nth-child(2n) {
    margin-left: 18px;
  }

  .questionTitle {
    line-height: 24px;
    font-size: 17px;
    font-weight: 500;
    color: ${neutral90};
    margin-bottom: 2px;
  }
  .questionContext {
    line-height: 21px;
    font-size: 14px;
    color: ${neutral70};
  }
  .arrow-box {
    display: none;
    position: absolute;
    top: 13.5px;
    right: 8px;
    padding: 9px;
    background-color: ${neutral35};
  }
  .arrow-style {
    font-size: 22px;
    color: ${neutral80};
  }
`;
