import styled from "styled-components";
import { colorVariable } from "../../common/globalStyle";

const { neutral90 } = colorVariable;
const widthNum = "2px";
const heightNum = "5px";
const marginNum = "2px";

export const ChatUnLikeWrapper = styled.div`
  position: relative;
  width: 16px;
  height: 16px;
  .unLike-bottom {
    position: absolute;
    top: 16px;
  }
  .point-box {
    display: flex;
    height: ${heightNum};
    width: 18px;
    justify-content: space-between;
  }
  .point-left,
  .point-center,
  .point-right {
    width: ${widthNum};
    background-color: ${neutral90};
    margin: 0 ${marginNum};
    border-radius: 50px;
    opacity: 0;
  }
  .point-left {
    transform: rotate(35deg);
  }
  .point-center {
    transform: translate(0, 1px);
  }
  .point-right {
    transform: rotate(-35deg);
  }
  .unLike-point-aimation {
    animation: unLikePointAimation 600ms ease 1;
  }
  @keyframes unLikePointAimation {
    0% {
      margin-bottom: ${heightNum};
      width: ${widthNum};
      display: inline-flex;
    }
    75% {
      margin-bottom: 0;
      margin-top: 0;
      opacity: 1;
      width: ${widthNum};
    }
    100% {
      opacity: 0;
      margin-top: ${heightNum};
      width: 0;
    }
  }
  .unLike-aimation {
    animation: unLikeAimation 600ms ease 1 both;
  }
  @keyframes unLikeAimation {
    0% {
      transform: rotate(30deg);
    }
    75% {
      transform: rotate(-15deg);
    }
    100% {
      transform: rotate(0);
    }
  }
`;
