import React, { memo } from "react";
import { ChatConversationWrapper } from "./style";
import classNames from "classnames";
import avatar from "@/assets/img/avatar-2.png";

export default memo((props) => {
  const {
    hasBg,
    children,
    name,
    content,
    cursorFlicker,
    className,
    isError,
    img,
  } = props;
  return (
    <ChatConversationWrapper
      className={classNames(className)}
      cursorFlicker={cursorFlicker}
    >
      <div className="img-box">
        <img className="img-style" src={img ? img : avatar} alt="" />
        <span>{name}</span>
      </div>
      <div className={classNames("flex-1 m-l-12 ")}>
        {/* <div className={classNames("name-style", { error: isError })}>
          {name}
        </div> */}
        <div
          className={classNames("content-style", {
            error: isError,
            "b-g-c": hasBg,
          })}
        >
          {content}
        </div>
        <div className="operate-style">{children}</div>
      </div>
    </ChatConversationWrapper>
  );
});
