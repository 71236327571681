import styled from "styled-components";
import { colorVariable } from "@/common/globalStyle";

const { neutral30, othersWhite, primaryblue20, neutral80 } = colorVariable;

export const StopGenerateWrapper = styled.div`
  position: absolute;
  left: 50%;
  bottom: 129px;
  translate: -50%;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  border-radius: 12px;
  box-shadow: 0 4px 10px 0 rgba(96, 92, 114, 0.1);
  border: solid 1px ${neutral30};
  background-color: ${othersWhite};
  color: ${neutral80};
  &:hover {
    background-color: ${primaryblue20};
  }
  .stop-box {
    display: flex;
    align-items: center;
  }
  span {
    font-size: 14px;
  }
`;
