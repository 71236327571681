import styled from "styled-components";
import { colorVariable } from "@/common/globalStyle";
const { neutral80, neutral30 } = colorVariable;
export const ChatItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 12px;
  border-radius: 12px;
  cursor: pointer;
  &:hover {
    background-color: ${neutral30} !important;
  }
  .icon-box {
    width: 24px;
    height: 24px;
    overflow: hidden;
    border-radius: 4px;
  }
  .duihua-icon {
    font-size: 24px;
  }
  .m-r-10 {
    margin-right: 10px;
  }
  .content-style {
    flex: 1;
    min-width: 0;
    font-size: 14px;
    color: ${neutral80};
  }
`;
