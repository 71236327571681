import styled from "styled-components";

import { colorVariable } from "@/common/globalStyle";

const { bobblue1, neutral80, othersWhite, assistDanger50 } = colorVariable;

export const MenuWrapper = styled.div`
  display: flex;
  margin-left: auto;
  height: 100%;
  .itemStyle {
    display: flex;
    align-items: center;
    line-height: 20;
    font-size: 14px;
    font-weight: 500;
    color: ${neutral80};
    margin-right: 24px;
    cursor: pointer;
    &:hover,
    &.isActive {
      font-weight: 500;
      color: ${bobblue1};
    }
  }
  .badge {
    width: 18px;
    height: 18px;
    line-height: 18px;
    margin-left: 4px;
    border-radius: 23px;
    border: solid 1px ${othersWhite};
    background-color: ${assistDanger50};
    text-align: center;
    font-weight: 600;
    color: ${othersWhite};
  }
`;
