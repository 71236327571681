import React, { memo } from "react";
import IconSvg from "../SvgIcon";
import { ChatOnLikeWrapper } from "./style";
import classNames from "classnames";

export default memo((props) => {
  const { onClick, className, animation = true } = props;
  const handleClick = (e) => {
    onClick?.(e);
  };
  return (
    <ChatOnLikeWrapper className={className} onClick={(e) => handleClick(e)}>
      <IconSvg
        className={classNames("f-s-16 union-icon-absolute", {
          "display-none": !animation,
          "union-animation": animation,
        })}
        name="Union"
      />
      <IconSvg
        className={classNames("f-s-16 onLike-icon-absolute", {
          "onLike-animation": animation,
        })}
        name="like-fill"
      />
    </ChatOnLikeWrapper>
  );
});
