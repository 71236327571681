import React, { memo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { TabWrapper, PopoverContentWrapper } from "./style";
import classNames from "classnames";
import { Popover } from "antd";
import IconSvg from "@/components/SvgIcon";
import ChatModel from "../ChatModal";
import { currentModelId, setModelId } from "@/services/chatContext";

export default memo((props) => {
  const dispatch = useDispatch();
  const { value, confirm } = props;
  const modelId = useSelector(currentModelId);
  const [foldedData, setFoldedData] = useState([]);
  const [expandData, setExpandData] = useState([]);
  const [current, setCurrent] = useState("");
  const [moreSelect, setMoreSelect] = useState("更多");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [beforModelChange, setBeforModelChange] = useState({
    isMore: false,
    modelType: "",
    id: "",
  });
  useEffect(() => {
    const folded = [],
      expand = [];
    value.forEach((item) => {
      if (item.is_folded) {
        folded.push(item);
      } else {
        expand.push(item);
      }
    });
    setFoldedData(folded);
    setExpandData(expand);
  }, [value]);

  useEffect(() => {
    const id = modelId && modelId !== "" ? modelId : expandData[0]?.id;
    const index = value.findIndex((item) => {
      return item.id === id;
    });
    setCurrent(value[index]?.name);
    dispatch(setModelId(id));
  }, [modelId, value, expandData]);

  const closeHandel = (value) => {
    setIsModalOpen(value);
  };
  // const moreClick = (value) => {
  //   setIsModalOpen(true);
  //   setBeforModelChange({
  //     isMore: true,
  //     modelType: value.name,
  //     id: value.id,
  //   });
  // };
  const tabClick = (value) => {
    // setIsModalOpen(true);
    // setBeforModelChange({
    //   isMore: value.is_folded,
    //   modelType: value.name,
    //   id: value.id,
    // });
    confirmHandle({
      isMore: value.is_folded,
      modelType: value.name,
      id: value.id,
      description: value.description,
    });
  };

  const confirmHandle = (beforModelChange) => {
    // setIsModalOpen(false);
    setCurrent(beforModelChange.modelType);
    if (beforModelChange.isMore) {
      setMoreSelect(beforModelChange.modelType);
    } else {
      setMoreSelect("更多");
    }
    dispatch(setModelId(beforModelChange.id));
    confirm?.(beforModelChange);
  };
  const isInclude = foldedData.findIndex((item) => {
    return item.name === current;
  });
  const content = (
    <PopoverContentWrapper>
      {foldedData.map((item) => {
        return (
          <div
            className={classNames("select-item", {
              isSelect: current === item.name,
            })}
            key={item.name}
            onClick={(e) => tabClick(item)}
          >
            <span>{item.name}</span>
            <div className="select-icon-box">
              <IconSvg
                name="Frame"
                className={classNames("select-icon", {
                  "no-show": current !== item.name,
                })}
              />
            </div>
          </div>
        );
      })}
    </PopoverContentWrapper>
  );
  return (
    <TabWrapper>
      {expandData.map((item) => {
        return (
          <div
            key={item.name}
            className={classNames("itemStyle", {
              isActive: current === item.name,
            })}
            onClick={(e) => tabClick(item)}
          >
            {item.name}
          </div>
        );
      })}

      {foldedData.length !== 0 && (
        <Popover
          placement="bottomLeft"
          content={content}
          trigger="click"
          arrow={false}
          overlayClassName="my-light-popover-style"
        >
          <span
            className={classNames("itemStyle", {
              isActive: isInclude !== -1,
            })}
          >
            {moreSelect}
            <IconSvg name="ArrowDown" className="arrow-icon" />
          </span>
        </Popover>
      )}
      <ChatModel
        modelType={current}
        isModalOpen={isModalOpen}
        closeHandel={closeHandel}
        confirmHandle={confirmHandle}
      />
    </TabWrapper>
  );
});
