import React, { memo, useState, useEffect, useRef, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { marked } from "marked";
import hljs from "highlight.js";

import ChatTab from "@/components/ChatTab";
import ChatControl from "../../components/ChatControl";
import ChatTips from "./components/ChatTips";
import ChatHintQuestion from "./components/ChatHintQuestion";
import ChatInput from "@/components/ChatInput";
import StopGenerate from "./components/StopGenerate";
import ChatConversation from "@/components/ChatConversation";
import QuestionEdit from "@/components/QuestionEdit";
import AnswerContent from "./components/AnswerContent";
import {
  TopBoxWrapper,
  BottomBoxWrapper,
  CenterBoxWrapper,
  ConversationContentWrapper,
} from "./style";
import {
  addChatDialogue,
  chatDialogueList,
  setChatId,
  currentChatId,
  currentModelId,
  currentAppId,
  chatContextDataObject,
  setChatContext,
  currentContentisPend,
  setCurrentContentPendding,
  setCurrentContentId,
  pageChangeChat,
  slideup,
  setIsSlideup,
} from "@/services/chatContext";

import {
  historyChatList,
  historyChatStatus,
  clickSidebar,
  clickNewchat,
  setIsClickSidebar,
  setIsClickNewchat,
  userInfoStatus,
} from "@/services/sidebar";
import {
  modelList,
  chatGenerate,
  chatContentRegenerate,
  recommendQuestions,
} from "@/Api";
import { useCollect } from "@/hooks/collect";
import { useLoginModel } from "@/hooks/loginTips";
import { assembleParams } from "@/utils";
import classNames from "classnames";

export default memo(() => {
  const dispatch = useDispatch();
  const { collectHandle } = useCollect();
  const { content } = useLoginModel();
  const [isCollect, setIsCollect] = useState(false);
  let chatContext = useSelector(chatContextDataObject);
  let chatList = useSelector(chatDialogueList);
  let chatListView = [];
  let chatId = useSelector(currentChatId);
  const pageChangeChatList = useSelector(pageChangeChat);

  let modelId = useSelector(currentModelId);
  let appId = useSelector(currentAppId);
  const isPend = useSelector(currentContentisPend);
  const historyChat = useSelector(historyChatStatus);
  const isClickSidebar = useSelector(clickSidebar);
  const isClickNewchat = useSelector(clickNewchat);
  const userInfo = useSelector(userInfoStatus);
  const isSlideup = useSelector(slideup);
  const [newChatStatus, setNewChatStatus] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [modelListData, setModelListData] = useState([]);
  const [aborter, setAborter] = useState({});
  const [reAborter, setReAborter] = useState({});
  const [isAfresh, setIsAfresh] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [description, setDescription] = useState(null);
  const controller_chat_generate = new AbortController();
  const signal_chat_generate = controller_chat_generate.signal;
  const controller_chat_regenerate = new AbortController();
  const signal_chat_regenerate = controller_chat_regenerate.signal;
  const chatContextRef = useRef(null);
  const chatContextBoxRef = useRef(null);
  const getChatListView = () => {
    if (isPend) {
      chatListView = [...chatList];
    } else {
      chatListView = [...pageChangeChatList];
    }
  };
  getChatListView();
  useEffect(() => {
    if (isClickSidebar && isLoading) {
      stopHandle();
    }
    dispatch(setIsClickSidebar(false));
  }, [isClickSidebar]);
  useEffect(() => {
    if (isClickNewchat && isLoading) {
      stopHandle(true);
    }
    dispatch(setIsClickNewchat(false));
  }, [isClickNewchat]);
  useEffect(() => {
    setNewChatStatus(chatList.length === 0);
    const resizeObserver = new ResizeObserver((entries) => {
      if (chatContextBoxRef.current && !isSlideup) {
        for (let entry of entries) {
          chatContextBoxRef.current.scrollTop = entry.target.clientHeight;
        }
      }
    });
    if (chatContextRef.current) {
      resizeObserver.observe(chatContextRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [chatList]);

  useEffect(() => {
    const id = chatList[chatList.length - 1]?.id;
    if (isPend) {
      dispatch(setCurrentContentId(id));
    }
  }, [chatList, isPend]);
  useEffect(() => {
    getModelList();

    // 配置highlight
    hljs.configure({
      tabReplace: "",
      classPrefix: "hljs-",
      languages: [
        "CSS",
        "HTML",
        "JavaScript",
        "Python",
        "TypeScript",
        "Markdown",
      ],
    });
    // 配置marked
    marked.setOptions({
      renderer: new marked.Renderer(),
      highlight: (code) => {
        return hljs.highlightAuto(code).value;
      },
      pedantic: false,
      gfm: true,
      tables: true,
      breaks: false,
      sanitize: false,
      smartLists: true,
      smartypants: false,
      xhtml: false,
      mangle: false,
      headerIds: false,
    });
  }, []);
  //页面滚动
  useEffect(() => {
    let one = window.addEventListener(
      "scroll",
      () => {
        const difference =
          chatContextRef.current?.offsetHeight -
          chatContextBoxRef.current?.scrollTop -
          chatContextBoxRef.current?.offsetHeight;
        if (isLoading) {
          if (difference > 10) {
            dispatch(setIsSlideup(true));
          } else {
            dispatch(setIsSlideup(false));
          }
        }
      },
      true
    ); //监听滚动条变化
    if (!isLoading) {
      dispatch(setIsSlideup(false));
    }
    return () => {
      if (one) window.removeEventListener(one); //清除监听
    };
  }, [isLoading, chatList]);
  //监听到滚动条变化，处理的函数

  useEffect(() => {
    if (modelId) {
      getRecommendQuestions();
    }
  }, [modelId, newChatStatus]);
  useEffect(() => {
    const collect = historyChat?.favorites?.findIndex((item) => {
      if (newChatStatus) {
        return item.resource_id === modelId;
      } else {
        return item.resource_id === chatId;
      }
    });
    setIsCollect(collect !== -1);
  }, [historyChat, newChatStatus, modelId, chatId]);

  const answer = {
    author: {
      name: null,
      role: "Assistant",
    },
    children: [],
    data: {
      parts: [],
      type: "text",
    },
    id: "new-Assistant",
    parent: "new-User",
  };
  const getModelList = () => {
    modelList().then((res) => {
      const list = res?.candidates || [];
      setModelListData(list);
      setDescription(list[0].description);
    });
  };
  const getRecommendQuestions = () => {
    recommendQuestions({ service_id: modelId }).then((res) => {
      setQuestionList(res.candidates || []);
    });
  };
  const collectClick = () => {
    let params =
      chatId !== ""
        ? { session_id: chatId }
        : modelId !== ""
        ? { model_id: modelId }
        : { app_id: appId };
    collectHandle(params, isCollect, () => {
      setIsCollect(!isCollect);
      dispatch(historyChatList());
    });
  };
  const sendHandle = (value) => {
    dispatch(setCurrentContentPendding(true));
    setIsAfresh(false);
    setIsLoading(true);
    const parentId = chatContext?.current_content_id || null;
    let obj = {
      author: {
        name: null,
        role: "User",
      },
      children: [],
      data: {
        parts: [value],
        type: "text",
      },
      id: "new-User",
      parent: parentId,
    };
    let newChatContext = {};
    if (!chatContext.contents) {
      newChatContext["contents"] = {};
    } else {
      newChatContext["contents"] = { ...chatContext.contents };
    }
    newChatContext["contents"]["new-User"] = obj;
    newChatContext["contents"]["new-Assistant"] = answer;
    newChatContext["current_content_id"] = "new-Assistant";
    chatContext = newChatContext;
    dispatch(setChatContext(newChatContext));
    chatList = [...chatList, obj, { ...answer, pending: true }];
    dispatch(addChatDialogue([...chatList]));
    getChatGenerate(value, parentId);
  };
  const getChatGenerate = async (value, parentId) => {
    try {
      let params = {
        session_id: chatId,
        parent_content_id: parentId,
        service_id: modelId,
        prompt: value,
      };

      let paramsStr = assembleParams(params);
      const response = await chatGenerate(paramsStr, { signal_chat_generate });
      const reader = response.body.getReader();
      dispatch(historyChatList());
      setAborter(reader);
      chunkCache(reader, resultHandle);
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };
  const resultHandle = (data, result) => {
    let parentNode =
      chatContext["contents"][
        chatContext["contents"][chatContext["current_content_id"]].parent
      ];

    const questionChildren = [data.content_id];

    const question = {
      ...parentNode,
      ...{
        children: questionChildren,
        id: data.prompt_node_id,
      },
    };

    const newAnswer = {
      ...answer,
      ...{
        id: data.content_id,
        parent: data.prompt_node_id,
        data: {
          parts: [result],
          type: data.type,
          code: data.code,
        },
      },
    };
    let newChatContext = {};
    if (!chatContext.contents) {
      newChatContext["contents"] = {};
    } else {
      newChatContext["contents"] = { ...chatContext.contents };
    }
    newChatContext["contents"][question["id"]] = question;
    newChatContext["contents"][newAnswer["id"]] = newAnswer;
    if (newChatContext["contents"]["new-User"]) {
      delete newChatContext["contents"]["new-User"];
    }
    if (newChatContext["contents"]["new-Assistant"]) {
      delete newChatContext["contents"]["new-Assistant"];
    }
    newChatContext["current_content_id"] = newAnswer["id"];
    chatContext = { ...newChatContext };
    dispatch(setChatContext(newChatContext));
    chatList.splice(chatList.length - 2, 2, question, {
      ...newAnswer,
      pending: true,
    });
    dispatch(addChatDialogue([...chatList]));
  };
  const reGenerateHandle = async (value) => {
    setIsAfresh(true);
    setIsLoading(true);
    chatList = [...chatList];
    let currentParent = { ...chatList[chatList.length - 2] };
    currentParent.children = [...currentParent.children, answer.id];
    chatList.splice(chatList.length - 2, 2, currentParent, {
      ...answer,
      pending: true,
    });
    dispatch(addChatDialogue([...chatList]));
    try {
      let params = {
        session_id: chatId,
        content_id: value.id,
        service_id: modelId,
        prompt: currentParent.data.parts[0],
      };
      let paramsStr = assembleParams(params);
      const response = await chatContentRegenerate(paramsStr, {
        signal_chat_regenerate,
      });
      const reader = response.body.getReader();
      setReAborter(reader);
      chunkCache(reader, reResultHandle, true);
    } catch (error) {
      console.log(error);
      setIsLoading(true);
    }
  };
  const chunkCache = async (reader, callback, isShowLogin) => {
    const textDecoder = new TextDecoder(); // 创建解码器
    let result = "";
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        generateFinish();
        break;
      }
      const valueList = textDecoder.decode(value, { stream: true }).split("\n");
      let sessionId = "";
      for (let item of valueList) {
        const itemValue = item !== "" ? JSON.parse(item) : "";
        if (itemValue !== "") {
          if (itemValue.code === 0) {
            var str =
              itemValue.data && itemValue.data !== ""
                ? window.atob(itemValue.data)
                : "";
            const data = str !== "" ? JSON.parse(str) : { data: "" };
            result += data.data;
            if (data.data?.trim() !== "") {
              callback(data, result);
              sessionId = data.session_id;
            }
          } else {
            result += itemValue.msg;
            callback({ code: itemValue.code }, result);
            if (isShowLogin) {
              content();
            }
            break;
          }
        }
      }
      if (sessionId) {
        dispatch(setChatId(sessionId));
      }
    }
  };
  const reResultHandle = (data, result) => {
    const newAnswer = {
      ...answer,
      ...{
        id: data.content_id,
        parent: data.prompt_node_id,
        data: {
          parts: [result],
          type: data.type,
          code: data.code,
        },
      },
    };
    let children = [...chatList[chatList.length - 2].children];
    children.splice(children.length - 1, 1, newAnswer.id);
    let newCurrentParent = {
      ...chatList[chatList.length - 2],
      children: children,
    };

    chatList.splice(chatList.length - 2, 2, newCurrentParent, {
      ...newAnswer,
      pending: true,
    });

    dispatch(addChatDialogue([...chatList]));
    let newChatContents = {};
    newChatContents = { ...chatContext.contents };
    newChatContents[newCurrentParent["id"]] = newCurrentParent;
    newChatContents[newAnswer["id"]] = newAnswer;
    chatContext = {
      ...chatContext,
      contents: { ...newChatContents },
      current_content_id: newAnswer["id"],
    };
    dispatch(setChatContext(chatContext));
  };
  const stopHandle = (isClear) => {
    setIsLoading(false);
    let cancelHandel = null;
    if (isAfresh) {
      controller_chat_regenerate.abort();
      cancelHandel = reAborter?.cancel?.();
    } else {
      controller_chat_generate.abort();
      cancelHandel = aborter?.cancel?.();
    }
    if (isClear) {
      cancelHandel.then((res) => {
        dispatch(addChatDialogue([]));
        dispatch(setChatContext({}));
        dispatch(setChatId(""));
      });
    }
  };
  const generateFinish = () => {
    setIsLoading(false);
    const lastChild = {
      ...chatContext["contents"][chatContext["current_content_id"]],
      pending: false,
    };
    chatList.splice(chatList.length - 1, 1, lastChild);
    dispatch(addChatDialogue([...chatList]));
  };
  const confirmHanle = (value) => {
    setDescription(value.description);
    if (isLoading) {
      stopHandle(true);
    } else {
      dispatch(addChatDialogue([]));
      dispatch(setChatContext({}));
      dispatch(setChatId(""));
    }
  };
  const questionHandle = (value) => {
    sendHandle(value);
  };
  const getImgUrl = useMemo(() => {
    let index = modelListData.findIndex((item) => {
      return item.id === modelId;
    });
    return modelListData[index]?.img_url;
  }, [modelListData, modelId]);
  return (
    <>
      <TopBoxWrapper>
        <ChatTab
          value={modelListData}
          confirm={(value) => confirmHanle(value)}
        />
        <div className="m-l-auto">
          <ChatControl
            icon={isCollect ? "Stars2" : "Stars"}
            clickHandle={collectClick}
          />
          {/* <ChatControl icon="export" className="m-l-10" /> */}
        </div>
      </TopBoxWrapper>

      {!newChatStatus && (
        <CenterBoxWrapper
          className="chat-conversation-box"
          ref={chatContextBoxRef}
          id="chat-conversation-box"
        >
          <ConversationContentWrapper
            ref={chatContextRef}
            className={classNames({ "display-none": newChatStatus })}
          >
            {chatListView.map((item, index) => {
              const lastChild = index === chatListView.length - 1;
              let ele =
                item.author.role === "User" ? (
                  <ChatConversation
                    img={userInfo.avatar}
                    hasBg
                    content={item.data.parts}
                    className="content-space"
                    key={item.id}
                  >
                    <QuestionEdit className="m-t-2" />
                  </ChatConversation>
                ) : (
                  <AnswerContent
                    className={classNames("content-space", {
                      "display-none": item.status === "hidden",
                    })}
                    img={getImgUrl}
                    key={item.id}
                    item={item}
                    regenerate={reGenerateHandle}
                    isLast={lastChild}
                    marked={marked}
                  />
                );
              return ele;
            })}
          </ConversationContentWrapper>
        </CenterBoxWrapper>
      )}
      {newChatStatus && <ChatTips />}
      <BottomBoxWrapper>
        {newChatStatus && (
          <ChatHintQuestion
            value={questionList}
            className="m-b-40"
            onClick={(item) => {
              questionHandle(item);
            }}
          />
        )}
        <ChatInput
          sendHandle={(value) => sendHandle(value)}
          loading={isLoading}
        />
      </BottomBoxWrapper>
      {isLoading && <StopGenerate onClick={() => stopHandle()} />}
    </>
  );
});
