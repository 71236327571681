import React, { memo, useState } from "react";

import { ChatItemWrapper } from "./style";
import IconSvg from "@/components/SvgIcon";

export default memo((props) => {
  const { value, children, className, clickHandle, alwaysShow = false } = props;
  const [isShow, setIsShow] = useState(false);
  const chatMouseenter = () => {
    setIsShow(true);
  };
  const chatMouseleave = () => {
    setIsShow(false);
  };
  return (
    <ChatItemWrapper
      onMouseEnter={(e) => chatMouseenter()}
      onMouseLeave={(e) => chatMouseleave()}
      onClick={() => clickHandle(value.id)}
      className={className}
    >
      <div className="icon-box m-r-10">
        <IconSvg name="duihua" className="duihua-icon"></IconSvg>
      </div>

      <div className="ellipsis content-style">{value.title}</div>
      {alwaysShow ? <div>{children}</div> : isShow && <div>{children}</div>}
    </ChatItemWrapper>
  );
});
