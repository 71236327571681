import React, { memo } from "react";
import { QuestionWrapper } from "./style";
import IconSvg from "@/components/SvgIcon";
import classNames from "classnames";

export default memo((props) => {
  const { value, className, onClick } = props;

  const handleClick = (e, value) => {
    e.stopPropagation();
    onClick?.(value);
  };
  return (
    value.length !== 0 && (
      <QuestionWrapper className={className}>
        <div className="title">随便试试</div>
        <div className="context">
          {value.map((item) => {
            return (
              <div
                key={item.content}
                className="questionItem cursor-pointer"
                onClick={(e) => {
                  handleClick(e, item.content);
                }}
              >
                <div className="questionTitle ellipsis">{item.title}</div>
                <div className="questionContext ellipsis">{item.content}</div>
                <div className="arrow-box">
                  <IconSvg
                    name="arrow3"
                    className={classNames("arrow-style")}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </QuestionWrapper>
    )
  );
});
