import ChatButton from "@/components/ChatButton";
import IconSvg from "@/components/SvgIcon";
import { Modal } from "antd";
const { confirm } = Modal;
export const useLoginModel = () => {
  const goLogin = () => {
    Modal.destroyAll();
    console.log(process.env.REACT_APP_LOGIN_PATH);
    window.open(process.env.REACT_APP_LOGIN_PATH, "_blank");
  };
  const content = () => {
    confirm({
      icon: null,
      centered: true,
      title: (
        <div className="my-header-style">
          <span className="title">登录</span>
          <div className="close-box" onClick={() => Modal.destroyAll()}>
            <IconSvg name="close" className="close-icon" />
          </div>
        </div>
      ),
      className: "my-model",
      content: (
        <div className="my-model-content">
          登录超时,您可以取消以留在此页面，也可以重新登录?
        </div>
      ),
      footer: (
        <div className="my-model-footer">
          <ChatButton
            type="lightPrimary"
            size="large"
            padding="40px"
            onClick={() => Modal.destroyAll()}
          >
            取消
          </ChatButton>
          <ChatButton
            type="primary"
            size="large"
            padding="40px"
            onClick={() => goLogin()}
          >
            登录
          </ChatButton>
        </div>
      ),
    });
  };
  return {
    content,
  };
};
