import styled from "styled-components";
import { colorVariable, styleVariable } from "@/common/globalStyle";

const {
  neutral70,
  neutral80,
  neutral90,
  bobblue1,
  primaryblue5,
  primaryblue15,
  primaryblue20,
  primaryblue30,
  assistSafe10,
  assistSafe50,
  textQuaternary5,
  dark5,
} = colorVariable;
const { headerHeight } = styleVariable;
export const InfoWrapper = styled.div`
  .alert-enter {
    opacity: 0;
  }
  .alert-enter-active,
  .alert-enter-done {
    opacity: 1;
    transition: opacity 200ms;
  }
  .alert-exit {
    opacity: 1;
  }
  .alert-exit-active,
  .alert-exit-done {
    opacity: 0;
    transition: opacity 200ms;
  }
`;

export const UserWrapper = styled.div`
  height: ${headerHeight};
  display: flex;
  align-items: center;
  text-align: right;
  cursor: pointer;
  .userName {
    line-height: 22px;
    font-size: 16px;
    font-weight: 500;
    color: ${neutral90};
  }
  .userType {
    position: relative;
    top: -2px;
    font-size: 12px;
    line-height: 17px;
    color: #64748b;
  }
  .imgbox {
    position: relative;
    right: -4px;
    width: 40px;
    height: 40px;
    padding: 4px;
    margin-left: 8px;
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 32px;
      height: 32px;
      border-radius: 8px;
    }
    span {
      position: absolute;
      right: 4px;
      bottom: 4px;
      height: 32px;
      width: 32px;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-size: 16px;
    }
  }
  &:hover .imgbox {
    background-color: ${primaryblue30};
  }
`;

export const DropdownWrapper = styled.div`
  position: absolute;
  z-index: 10000;
  top: ${headerHeight};
  right: 0;
  width: 240px;
  text-align: left;
  background-color: #fff;
  background-color: #fff;
  border: solid 1px ${textQuaternary5};
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 19px 21px 0 rgba(0, 0, 0, 0.08);
  .display-flex {
    display: flex;
  }
  .align-items {
    align-items: center;
  }
  .top-box {
    padding: 20px 22px;
    background-color: ${primaryblue5};

    .top-box-user-name {
      line-height: 20px;
      color: ${neutral90};
      font-weight: 500;
      font-size: 16px;
      flex: 1;
    }
    .top-box-user-id {
      height: 18px;
      color: ${neutral70};
      font-size: 13px;
      line-height: 18px;
      margin-top: 4px;
    }
    .tag {
      height: 24px;
      margin-left: auto;
      padding: 0 12px;
      color: ${dark5};
      font-size: 12px;
      line-height: 24px;
      border: solid 1px ${dark5};
      border-radius: 2px;
    }
  }
  .menu {
    padding: 4px;
    > div {
      display: flex;
      align-items: center;
      font-size: 13px;
      height: 36px;
      padding: 0 16px;
      border-radius: 12px;
      color: ${neutral80};
      cursor: pointer;
    }
    > div:hover {
      background-color: ${primaryblue15};
    }
  }
`;
export const TagWrapper = styled.div`
  &.type {
    display: flex;
    align-items: center;
    width: fit-content;
    height: 22px;
    padding: 0 8px;
    font-size: 12px;
    line-height: 22px;
    background-color: ${primaryblue20};
    border-radius: 11px;
  }
  &.master-style,
  &.admin-style {
    color: ${bobblue1};
    background-color: ${primaryblue20};
  }
  &.sub-style {
    color: ${assistSafe50};
    background-color: ${assistSafe10};
  }
  .m-r-2 {
    margin-right: 2px;
  }
`;
