import styled from "styled-components";

export const CapyCodeWrapper = styled.div`
  display: flex;
  justify-content: end;
  padding: 8px;
  background-color: #2f2f2f;
  .code-capy-box {
    display: flex;
    align-items: center;
  }
  span,
  svg {
    color: #b4b4b4;
  }
`;
