import styled from "styled-components";

import { colorVariable } from "../../common/globalStyle";

const { neutral20, neutral90 } = colorVariable;

export const ChatConversationWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["cursorFlicker"].includes(prop),
})`
  display: flex;
  border-radius: 12px;

  .img-box {
    position: relative;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    overflow: hidden;
    margin: 6px 0;
    span {
      position: absolute;
      right: 0;
      bottom: 0;
      height: 32px;
      width: 32px;
      text-align: center;
      line-height: 32px;
      color: #fff;
      font-size: 16px;
    }
  }
  .img-style {
    width: 100%;
  }
  .name-style {
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    color: ${neutral90};
    margin-bottom: 2px;
  }
  .content-style {
    font-size: 16px !important;
    word-wrap: break-word;
    padding-top: 6px;
    word-break: break-all;
    &.b-g-c {
      display: inline-block;
      border-radius: 12px;
      background-color: ${neutral20};
      padding: 12px 20px;
    }
  }

  .markdown-body > :last-child::after {
    display: ${(props) => (props.cursorFlicker ? "inline" : "none")};
    content: "■";
    font-family: "Söhne Circle", system-ui, -apple-system, "Segoe UI", Roboto,
      Ubuntu, Cantarell, "Noto Sans", sans-serif;
    line-height: normal;
    margin-left: 0.25rem;
    position: relative;
    top: 1px;
    animation: 0.7s van-cursor-flicker infinite;
  }
  @keyframes van-cursor-flicker {
    from {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;
